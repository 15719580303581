<template>
  <div class="dashboard">
    <div class="data">
      <div class="grid-wrapper grid-wrapper--top">
        <div class="grid-tile type-tile">
          <label class="grid-tile__label">
            {{$t('oapps.commons.dataRepresentationLabel')}}
          </label>
          <span v-if="types.length > 1"
                class="grid-tile__value grid-tile__value--multi">
            <button v-for="(type, idx) in types"
                    :key="idx"
                    class="visu-type-button o-button o-button--squared o-button--bordered"
                    :class="typeButtonClass(type.value)"
                    @click="selectedType = type.value">
              {{$t(`oapps.commons.${type.label}`)}}
            </button>
          </span>
          <span v-else
                class="grid-tile__value">
            {{$t(`oapps.commons.${types[0].label}`)}}
          </span>
        </div>
      </div>

      <div class="grid-wrapper">
        <div v-for="d in data"
             :key="d.name"
             class="grid-tile">
          <label class="grid-tile__label">
            {{ $t(`oapps.mean.${d.label.toLowerCase()}`) }}
          </label>
          <span class="data-value grid-tile__value">
            {{ d.value ? `${d.value.toFixed(2)} ${d.unit}` : $t('oapps.commons.noData') }}
          </span>
        </div>
      </div>
      <div class="grid-wrapper">
        <GraphCard v-for="(item,id) in graphData"
                   :key="id"
                   :title="item.name"
                   :class="(sidebarExpanded ? 'graph-card-extended' : 'graph-card')">
          <graph-extended v-if="sidebarExpanded"
                          :data="item.values"
                          :unit="item.unit" />
          <graph-sidebar v-if="!sidebarExpanded"
                         :data="item.values"
                         :unit="item.unit" />
        </GraphCard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import GraphCard from '@/oapps/commons/graph-card.vue';
import GraphSidebar from '@/oapps/commons/graph-sidebar.vue';
import GraphExtended from '@/oapps/commons/graph-extended.vue';

import heatmapRainbowMixin from '@/oapps/mixins/heatmapRainbow.js';
import graphMixin from '@/oapps/mixins/graph.js';

export default {
  name: 'CO2Dashboard',
  components: { GraphCard, GraphSidebar, GraphExtended },
  mixins: [heatmapRainbowMixin, graphMixin],
  props: {
    sidebarExpanded: {
      type: Boolean,
      default: false,
    },
    oapp: {
      type: Object,
      default: null,
    },
    global: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      data: [
        {
          label: 'CO2',
          name: 'co2.value',
          value: undefined,
          unit: 'ppm',
        },
      ],
      selectedType: '',
      types: [],
    };
  },
  computed: {
    dataClass() {
      return this.sidebarExpanded ? 'data__label col-1' : 'data__label col-5';
    },
  },
  watch: {
    global: {
      handler(val) {
        if (!this.creatingData) this.createData();
        this.$emit(
          'input',
          val && val.total !== undefined && val.total !== null ? { label: `${val.total.toFixed(2)} ppm`, value: val.total } : null
        );
      },
      deep: true,
    },
    selectedType(val) {
      const type = this.types.find(t => t.value === this.selectedType);
      if (type) {
        this.$store.commit('oapp/update', { oappId: this.oapp.id, data: { type: type.value } });
        this.$store.dispatch('oapp/fetchCurrentOappData');
      }
    },
  },
  created() {
    this.types = this.oapp.types.map(t => ({ label: t, value: t }));
    this.selectedType = this.oapp.type;
  },
  methods: {
    ...mapActions('oapps', ['fetchCurrentOappData']),
    ...mapMutations('oapps', {
      updateCurrentType: 'UPDATE_CURRENT_TYPE',
    }),
    typeButtonClass(type) {
      return {
        'o-button--black': this.selectedType === type,
        'o-button--shadows': this.selectedType !== type,
        'o-button--hover': this.selectedType !== type,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.dashboard
  .select-field
    top 8px
    left 10px
    font-weight 700
    font-size $fs-h3
  .type-selector
    margin 10px 10px 0px 10px
  .data-value
    font-weight 700
    font-size $fs-h1
  .graph-card
    margin-bottom $border-thick
    max-width 400px
    width 100%
    &:last-child
      margin-bottom 0
  .graph-card
    max-width 2000px
  .type-tile
    width 100%
  .grid-tile__value--multi
    display flex
    justify-content center
    width 100%
  .visu-type-button
    flex 1
    background-color white
    text-transform uppercase
    &:first-child
      margin-right $space-1

.grid-tile__label
  margin-bottom $space-2
</style>
